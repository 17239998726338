<template>
  <!-- Admin template layout -->
  <section id="container">
    <!--header start-->
    <header class="header black-bg d-print-none">
      <div class="sidebar-toggle-box text-white" @click="toggleMenu()">
        <i class="fas fa-bars"></i>
      </div>
      <!--logo start-->
      <a href="/" class="logo text-white"><div>OrderNow</div></a>
      <!--logo end-->
      <div class="top-nav ">
        <User-Login-Drop-Down></User-Login-Drop-Down>
      </div>
    </header>
    <!--header end-->
    <!--sidebar start-->
    <aside>
      <div
        id="sidebar"
        v-bind:class="{ hideSidebar: showMenu }"
        class="sidebar light-sidebar-nav"
      >
        <!-- sidebar menu start-->
        <ul class="sidebar-menu" id="">
          <li class="sub-menu">
            <router-link :to="{ name: 'items' }">
              <i class="fas fa-laptop"></i>
              <span>{{ $t("items.show") }}</span>
            </router-link>
            <!-- <ul class="sub">
                          <li><router-link class="btn btn-light" :to="({ name: 'items'})">{{ $t("items.all") }}</router-link></li>
                          <li><router-link  class="btn btn-light" :to="({ name: 'itemnew'})">{{ $t("items.new") }}</router-link> </li>
                      </ul> -->
          </li>

          <li>
            <router-link :to="{ name: 'metadata' }">
              <i class="fas fa-laptop"></i>
              <span>{{ $t("items.metadata") }}</span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'projects' }">
              <i class="fas fa-tasks"></i>
              <span>Projects</span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'companies' }">
              <i class="fas fa-building"></i>
              <span>Companies</span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'users' }">
              <i class="fas fa-user"></i>
              <span>Users</span>
            </router-link>
          </li>

          <!-- <li>
                      <router-link :to="({ name: 'login'})">
                          <i class="fas fa-user"></i>
                          <span>Login Page</span>
                     </router-link>
                  </li> -->

          <li>
            <router-link :to="{ name: 'configuration' }">
              <i class="fas fa-laptop"></i>
              <span>{{ $t("admin.configMenu") }}</span>
            </router-link>
          </li>
        </ul>
        <!-- sidebar menu end-->
      </div>
    </aside>
    <!--sidebar end-->
    <!--main content start-->
    <section id="main-content">
      <section class="wrapper">
        <!-- page start-->
        <div class="row mt-4">
          <div class="col-lg-12">
            <router-view />
            <br />
          </div>
        </div>
      </section>
    </section>
    <!--main content end-->

    <!--footer start-->
    <footer class="site-footer"></footer>
    <!--footer end-->
  </section>
  <!-- <b-container >

    <h3>Admin settings</h3>
    <hr>
    
     <router-link class="btn btn-light" :to="({name : 'items'})"> {{ $t("Items") }} settings</router-link>    

    <b-button variant="danger" class="m-3" @click="signOutBtn_click">Sign out</b-button>


    <router-view/>
    
  </b-container> -->
</template>

<script>
import UserLoginDropDown from "@/components/UserLoginDropDown";

export default {
  name: "admin",
  components: {
    UserLoginDropDown
  },

  data() {
    return {
      showMenu: false
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

.image-avatar {
  height: 25px;
  margin-right: 10px;
}

.hideSidebar {
  display: none;
}

@media (max-width: 768px) {
  .logo div {
    display: none;
  }
  .logo:after {
    content: "Order";
  }
}
</style>
